* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: black;
}


@font-face {
    font-family: 'A';
    src: local('A'), url(../fonts/E.ttf) format('opentype');
}
@font-face {
    font-family: 'M';
    src: local('M'), url(../fonts/M.ttf) format('truetype');
}
@font-face {
    font-family: 'B';
    src: local('B'), url(../fonts/B.ttf) format('truetype');
}
@font-face {
    font-family: 'I';
    src: local('I'), url(../fonts/I.ttf) format('truetype');
}


.main {
background-size: 500px;
    background-position: top;
    background-repeat: no-repeat;

    background-blend-mode: multiply;

    max-width: 1100px;
    margin: 0 auto  ;
    width: 100%;
    height: 100%;
    padding: 10px;

}

