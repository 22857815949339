
.bar {
    width: 100%;
    height: 60px;
    margin-top: 10px;
    display: flex;
}

.menu {
    width: 100%;
    height: 50px;

    display: flex;
}

.menu ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.btnf{
    height: 70px!important;
    text-transform: uppercase;
}

.menu ul li {
    letter-spacing: 3px;
    text-decoration: none;
    font-family: "A", sans-serif;
    cursor: pointer;
    color: white;
}

.menu ul li:hover a {

    color: #ECCA8B;
}

a {
    text-decoration: none;
    outline: none;
    color: white;
}

@media screen and (max-width: 1000px) {
    .menu {
        display: none;
    }

    .IconsR {
        display: flex;
    }
}

@media screen and (min-width: 1000px) {

    .IconsR {
        display: none !important;
    }
}


.main_block_m5 {
    width: 100%;

    display: flex;

    max-width: 650px;

}

@media screen and (max-width: 590px) {
    .footer ul {
        flex-direction: column;

        justify-content: start;
    }

    .footer ul li {
        width: 100% !important;
        margin-top: 15px;
    }

    .main_block_m5 ul {
        margin-left: 0;
        padding-left: 0;
    }
}

.footer {
    width: 100%;
    height: auto;
    margin: 60px auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    overflow: hidden;

}

.footer .main_block_text {
    display: flex;
    align-items: end;
}

.footer ul {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
}

.footer ul li {
    list-style: none;
    width: auto;
    color: white;
    font-family: "M", sans-serif;
    font-weight: 700;
}

.logo {
    width: 60px;
    height: 60px;
}

.main_block {

    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;

    box-sizing: border-box;
    object-fit: cover;
    flex-direction: column;

    justify-content: center;
}


.main_contacts {
    width: 100%;

    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main_contacts_hor {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
}

.main_contacts input, .main_contacts button {
    width: 100%;
    background-color: #0F0F0F;
    color: white;
    font-family: "M", sans-serif;
    max-width: 200px;
    margin: 5px;
    height: 50px;
    border-style: none;
    font-size: 15px;
    border-radius: 10px;
    outline: none;
    text-decoration: none;
    padding: 10px;
}

.main_contacts button {
    cursor: pointer;
    background: rgb(200, 168, 107);
    color: black;
    font-size: 18px;
    background: linear-gradient(180deg, rgba(200, 168, 107, 1) 0%, rgba(148, 124, 78, 1) 100%);
    font-family: "B", sans-serif;
    font-weight: 500;
}
.main_contacts button:hover {

    background: white;

    color: black!important;

}
.main_contacts textarea {
    margin-bottom: 10px;
    margin-top: 4px;
    resize: none;
    background-color: #0F0F0F;
    color: white;
    font-family: "M", sans-serif;
    font-size: 15px;
    width: 100%;
    max-width: 410px;
    margin-left: 5px;
    margin-right: 5px;
    height: 100px;
    border-style: none;
    border-radius: 10px;
    outline: none;
    text-decoration: none;
    padding: 10px;
}

.main_block_m3 {

  background-color: white;

    padding: 0;

    margin-top: 10px;
    margin-bottom: 10px;
   max-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 560px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;

}

.origin{
    border-radius: 10px;
   opacity: 0;
    padding: 10px!important;
    background-color: white!important;
}



@media screen and (max-width: 500px) {
    .main_contacts input, .main_contacts button {
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        height: 50px;

    }
}

.m1 {
    width: 100%;
    height: 100%;
}

.m2 {
    z-index: 1000;
    position: absolute;
    width: 50%;
    top: 0;

}

.main_block_texta {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-bottom: 10px;

    /* Начальные параметры для эффекта появления */
    opacity: 1;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.main_block_text{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-bottom: 10px;

    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.origin.appear {

    opacity: 1;
    transform: translateY(0);
}


.main_block_m1 h1 {
    font-family: A, sans-serif;
    font-size: 15px;
    color: #ECCA8B;
    letter-spacing: 3px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
    margin: 0 auto;
}
.main_block_m1.mz1  svg{
  width: 35px;

    background-color: white;
  stroke: #e73636;
    margin-right: 10px;

   text-align: left!important;
    height: 35px!important;
}



.main_block_m1 {
    width: auto;
    text-align: left!important;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px;
    border-radius: 20px;
    justify-content: start;

    background-color: #070707;


    height: 100%;

}



.main_block_about {
    width: 100%;
    margin: 5px;
    max-width: 500px;
    min-height: 220px;
    height: auto;
    padding: 10px 10px 60px 10px;
    background-color: #101010;
    border-radius: 10px;
}

.main_block_about svg{
    position: absolute;
    width: 50px;
    height: 50px;
    right: 10px;
    bottom: 10px;

    box-sizing: border-box;
    overflow: hidden;

    margin-right: 10px;
    color: rgba(255, 255, 255, 0.2);
}

.main_block_ma {

    display: flex;
    align-items: start;

    flex-wrap: wrap;
}

.main_block_m2 {
    width: auto;
    min-width: 300px;
    display: flex;
    margin: 20px auto;
    align-items: center;
    justify-content: center;

    height: 50px;
}

.mz1 {

    max-width: 650px;
    width:100%;
    padding: 25px;
}
.origin .main_block_m3{
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mz1 h2 {

    max-width: 530px;

}

.main_block_icons {
    width: 100%;
    height: 50px;

    display: flex;
    align-items: center;

    justify-content: space-between;
}

.main_block_icons svg {
    stroke: black;
    padding: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: white;
    border: none!important;
    border-radius: 10px;
    transition: background-color 0.3s ease, color 0.3s ease, padding 0.3s ease, transform 0.3s ease;
}

.main_block_icons svg:hover {
    background: linear-gradient(180deg, rgba(200, 168, 107, 1) 0%, rgba(148, 124, 78, 1) 100%);
    stroke: white;
    transform:scale(120%); /* Увеличение длины */
}
.main_block_mz_itema h1 {
    font-family: B, sans-serif;
    font-size: 25px;
    color: #ECCA8B;
    letter-spacing:1px;
    text-transform: uppercase;
}

.main_block_mz_itema p {
    font-family: M, sans-serif;
    font-size: 14px;

    padding: 0;
    color: white;
    font-weight: 400;
    line-height: 19px;
    margin: 0 auto;
    letter-spacing: 0.2px;
}
.main_block_about h1{
    display: flex;
    align-items: center;
}
.main_block_text h1 {
    font-family: A, sans-serif;
    margin: 0;
    width: auto;

    padding: 0;
    font-size: 30px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background: linear-gradient(180deg, #ECCA8B, #B08B5C); /* Gradient from #ECCA8B to a darker color, top to bottom */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow to enhance sharpness */
}
.main_block_ma .main_block_about h1{
    font-size: 20px!important;
}


.main_block_text h2 {
    font-family: I, sans-serif;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 !important;

}
.main_block_about{
    position: relative;
    overflow: hidden;
}
.main_block_texta h1 {
    font-family: A, sans-serif;
    margin: 0;
    padding: 0;
    font-size: 30px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background: linear-gradient(180deg, #ECCA8B, #B08B5C); /* Gradient from #ECCA8B to a darker color, top to bottom */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.1); /* Subtle shadow to enhance sharpness */
}

.main_block_texta h2 {
    font-family: I, sans-serif;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 !important;

}
.main_block_mz {
    width: 100%;
    height: auto;
    color: white;
    margin-top: 50px;
}

.main_block_mz h1 {
    font-family: A, sans-serif;
    font-size: 15px;
    color: #ECCA8B;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.main_m3_video_two{
    width: 100%;
    height: auto;
    filter: grayscale(30%);
}
.f1{
    background-color: transparent!important;
}
.main_video_two{
    width: 100%;
    filter: grayscale(100%);
    height: 200px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 20px;
}
.main_video{
    width: 100%;
    object-fit: cover!important;
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 20px;
}
.main_video video{
    width: 100%;
    border-radius: 10px;
}


.main_block_mz p {
    font-family: M, sans-serif;
    font-size: 14px;
    max-width: 500px;
    padding: 0;
    margin: 0;
    color: white;
}

.main_block_mz_items {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

.main_m3_video {
    width: 100% !important;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

}
.f1{
    opacity: 0;
}

.main_block_m4{
    padding-top: 20px;
}
.main_block_mz_item {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    background-color: #101010;
    border-radius: 10px;
    max-width: 520px;
    min-height: 200px;
    display: flex;
    padding: 20px;
    justify-content: left!important;
    text-align: left!important;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    height: auto;
}
.fade-in {
    opacity: 1;

    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/* State when the element is intersecting */
.fade-in.intersecting {
    opacity: 0;
    transform: translateY(0);
}
.main_block_mz_itemsa {
    padding: 10px;
    width: 100%;

    align-items: start;
    justify-content: center;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}

video {
    display: block;
    width: 100%;
    height: auto;
}

.video-not-supported {
    text-align: center;
    color: #888;
    font-size: 1rem;
    padding: 10px;
}

.mbm1{
    width: 100% !important;
    max-width: none!important;
}

.main_block_mz_itema h2 {
    font-family: B, sans-serif;
    font-size: 15px;
    color: #ffffff;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 0 !important;
    margin-bottom: 0;
}


.main_block_mz_itema {
    padding: 10px;
background-color: #0e0e0e;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;

    margin: 10px;
    height: 100%;
    min-height: 300px;
}


.team {
    width: 100%;
    height: auto;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.team_item {
    width: auto;
    max-width: 250px;
    margin: 10px;
    text-align: center;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

}

.team_title {
    width: 100%;
    text-align: center;
    font-family: A, sans-serif;
    font-size: 30px;
    color: #ECCA8B;
    letter-spacing: 4px;
}


.team_img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #282828;
}

.team_item h1 {
    color: white;
    font-family: A, sans-serif;
    font-size: 15px;
    letter-spacing: 2px;
}

.team_item h2 {
    color: #ECCA8B;
    font-family: M, sans-serif;
    font-size: 14px;
    line-height: 0;
}

.team_item p {
    color: white;
    font-family: M, sans-serif;
    font-size: 13px;
    margin-top: 25px;

}

.main_block_text p {
    font-family: M, sans-serif;
    font-size: 14px;
    padding: 2px;
    color: white;
    font-weight: 400;
    line-height: 19px;
    margin: 0 auto;
    letter-spacing: 0.2px;
}

.main_block_texta p {
    font-family: M, sans-serif;
    font-size: 14px;
    padding: 2px;
    color: white;
    font-weight: 400;
    line-height: 19px;
    margin: 0 auto;
    letter-spacing: 0.2px;
}
.button {
    margin-top: 15px;
    width: auto;
    height: 50px;
    background-color: #181818;
    font-family: M, sans-serif;
    color: black;
    cursor: pointer;
    font-weight: 700;
    text-align: left!important;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 25px;
    transition: background-color 0.3s ease, color 0.3s ease, padding 0.3s ease; /* Add transition properties */
}

.button:hover {
    background-color: #ffffff;
    color: black; /* Ensure this is the desired color */
    padding: 25px; /* Padding can be adjusted if you want a different effect */
}
.button:hover a {

    color: black !important;

}


.rights {
    margin-top: 10px;
    margin-left: 25px;
    color: white;
    font-family: "M", sans-serif;
}

